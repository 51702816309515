import React, { useState, useEffect } from "react"
import ItemModal from "../components/itemModal"
import Layout from "../components/layout"
import Seo from "../components/seo"
import CsvDownloadButton from "../components/csvDownloadButton"
import { useLocation } from "@reach/router"
import useArticleRange from '../hooks/useArticleRange'
import qs from 'qs';
import {
  Dimmer,
  Header,
  Icon,
  Loader,
  Pagination,
  Segment
} from 'semantic-ui-react'
import 'instantsearch.css/themes/satellite.css';

const Periodicals = () => {
  const [periodicals, setPeriodicals] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);

  const handleClick = (e, { activePage }) => {
    setPage(activePage);
  }

  useEffect(() => {
    setIsLoading(true);
    fetch(`https://admin.curranindex.org/items/periodicals/?fields=id,title,abbreviation,count(articles)&page=${page}&limit=20&sort=title&meta=total_count`, {
      mode: 'cors'
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw response
      })
      .then(data => {
        setPeriodicals(data.data);
        setTotalPages(Math.ceil(data.meta.total_count / 20));
      })
      .catch(error => {
        console.error("Error fetching data: " + error);
      })
      .finally(() => setIsLoading(false))
  }, [page])

  return (
    <Layout>
      <Seo title="Periodicals" />
      { isLoading &&
        <Segment style={{height: "800px"}}><Dimmer active inverted><Loader content='Loading' inverted /></Dimmer></Segment>
      }
      { !isLoading &&
        <div className="periodical-browse">
          <div style={{width: '20%', marginBottom: "14px"}}>
            <CsvDownloadButton
              index={"periodical"}
              searchState={{sortBy: "periodicals:title:asc"}}
            />
          </div>
          <Pagination
            activePage={page}
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            onPageChange={handleClick}
            totalPages={totalPages}
          />
          <Segment className="results">
            <div className="ais-Hits">
              <ul className="ais-Hits-list">
                { periodicals.map(periodical => (
                  <Card periodical={periodical} />
                )) }
              </ul>
            </div>
          </Segment>
          <Pagination
            activePage={page}
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            onPageChange={handleClick}
            totalPages={totalPages}
          />
        </div>
      }
    </Layout>
  )
}

const Card = ({ periodical }) => {
  const location = useLocation();
  const urlToSearchState = ({ search }) => qs.parse(search.slice(1));
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (urlToSearchState(location).showModal && (urlToSearchState(location).showModal === 'true') !== showModal) {
      setShowModal(urlToSearchState(location).showModal === 'true')
    }
  }, [location])

  const articleRange = useArticleRange(periodical)

  return (
    <li className="ais-Hits-item">
      <Segment
        className="hit periodical"
        key={periodical.id}
        onClick={() => {
          if (!showModal) {
            setShowModal(true)
          }
        }}
      >
        { showModal &&
          <ItemModal
            selection={{ index: 'periodical', id: periodical.id }}
            onClose={() => setShowModal(false)}
            showModal={showModal}
          />
        }
        <Header as="h3">
          {periodical.title} ({periodical.abbreviation})
        </Header>
        <div style={{fontSize: "1em"}}>
          <Icon name="calendar alternate outline" size="small" />
          {articleRange ? articleRange.join(' - ') : ''}
        </div>
        <div style={{fontSize: "1em"}}>
          <Icon name="newspaper outline" size="small" />
          {periodical.articles_count.toLocaleString()} article{periodical.articles_count === 1 ? '' : 's'}
        </div>
      </Segment>
    </li>
  )
}

export default Periodicals
